body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --theme-primary: #940000;
  --theme-secondary: #C20000;
  --theme-third: #F20000;

  --theme-gray-primary: #666666;
  --theme-gray-secondary: #333333;
  --theme-gray-third: #000000;

  --theme-white-primary: #FFFFFF;
  --theme-white-secondary: #E2E2E2;
  --theme-white-third: #B3B3B3;
}

.theme-text-primary{
  color: var(--theme-primary);
}

.theme-bg-primary{
  background-color: var(--theme-primary);
}

.theme-bg-secondary{
  background-color: var(--theme-secondary);
}

.theme-bg-third{
  background-color: var(--theme-third);
}

.theme-bg-gray-primary{
  background-color: var(--theme-gray-primary);
}

.theme-bg-gray-secondary{
  background-color: var(--theme-gray-secondary);
}

.theme-bg-gray-third{
  background-color: var(--theme-gray-third);
}

.theme-bg-white-primary{
  background-color: var(--theme-white-primary);
}

.theme-bg-white-secondary{
  background-color: var(--theme-white-secondary);
}

.theme-bg-white-third{
  background-color: var(--theme-white-third);
}
